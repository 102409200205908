import React from 'react';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy } from 'react-icons/fa';
import toast from 'react-hot-toast';

const ContentWrapper = styled.div`
  line-height: 1.7;
  color: #4b5563;
  font-family: 'Inter', sans-serif;

  @media (max-width: 768px) {
    line-height: 1.6;
    font-size: 0.95rem;
  }
`;

const Heading = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  color: #111827;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
`;

const Subheading = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  color: #1f2937;
  margin-top: 40px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-top: 30px;
    margin-bottom: 15px;
  }
`;

const Paragraph = styled.p`
  font-size: 1.125rem;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 15px;
  }
`;

const CodeContainer = styled.div`
  position: relative;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  overflow-x: auto;
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;

const CopyIcon = styled(FaCopy)`
  color: #4b5563;
  cursor: pointer;
  align-self: flex-end;
  margin-bottom: 10px;

  &:hover {
    color: #111827;
  }
`;

const CodeBlockStyled = styled.pre`
  background-color: #282c34;
  color: #ffffff;
  padding: 15px;
  border-radius: 6px;
  font-family: 'Courier New', monospace;
  font-size: 14px;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
  line-height: 1.5;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;

  &:last-child {
    border-bottom: none;
  }
`;

const TableHeader = styled.th`
  padding: 15px;
  text-align: left;
  background-color: #f1f1f1;
  font-weight: bold;
`;

const TableCell = styled.td`
  padding: 20px;
  text-align: left;
`;

function EndpointPage({ endpoint }) {
  const formatFieldName = (fieldName) => {
    return fieldName
      .split(',')
      .map((segment) => <div key={segment}>{segment.trim()}</div>);
  };

  const handleCopy = () => {
    toast.success('Copied to clipboard!');
  };

  const formatDescription = (description) => {
    return description.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
        <br />
      </React.Fragment>
    ));
  };

  return (
    <ContentWrapper>
      <Heading>{`${endpoint.method} ${endpoint.path}`}</Heading>
      <Paragraph>{formatDescription(endpoint.description)}</Paragraph>

      {endpoint.path === '/api/exam_upload_attachment/' && (
        <div>
          <Subheading>Authorization:</Subheading>
          <Paragraph>
            Please include your API key as a Bearer token in the Authorization
            header when making this request. Example:
          </Paragraph>
          <CodeBlockStyled>
            Authorization: Bearer your_api_key_here
          </CodeBlockStyled>
        </div>
      )}

      <Subheading>Example Request Payload:</Subheading>
      {endpoint.requestPayload ? (
        <CodeContainer>
          <CopyToClipboard
            text={JSON.stringify(JSON.parse(endpoint.requestPayload), null, 2)}
            onCopy={handleCopy}
          >
            <CopyIcon title='Copy to clipboard' />
          </CopyToClipboard>
          <CodeBlockStyled>
            {JSON.stringify(JSON.parse(endpoint.requestPayload), null, 2)}
          </CodeBlockStyled>
        </CodeContainer>
      ) : (
        <Paragraph>No request payload available</Paragraph>
      )}

      <Subheading>Parameters:</Subheading>
      {endpoint.Parameters.length > 0 ? (
        <ul>
          {endpoint.Parameters.map((param) => (
            <li key={param.id}>
              <strong>{param.name}</strong> ({param.type}) -{' '}
              {param.required ? 'Required' : 'Optional'}: {param.description}
            </li>
          ))}
        </ul>
      ) : (
        <Paragraph>No parameters</Paragraph>
      )}

      <Subheading>Request Fields:</Subheading>
      {endpoint.RequestFields && endpoint.RequestFields.length > 0 ? (
        <Table>
          <thead>
            <TableRow>
              <TableHeader>Field</TableHeader>
              <TableHeader>Explanation</TableHeader>
            </TableRow>
          </thead>
          <tbody>
            {endpoint.RequestFields.map((field) => (
              <TableRow key={field.id}>
                <TableCell>{formatFieldName(field.field)}</TableCell>
                <TableCell>{field.explanation}</TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      ) : (
        <Paragraph>No request fields available</Paragraph>
      )}

      <Subheading>Responses:</Subheading>
      {endpoint.Responses.length > 0 ? (
        endpoint.Responses.map((response) => (
          <div key={response.id}>
            <Paragraph>
              <strong>Status Code:</strong> {response.statusCode} -{' '}
              {response.description}
            </Paragraph>
            <CodeBlockStyled>
              {JSON.stringify(JSON.parse(response.example), null, 2)}
            </CodeBlockStyled>
          </div>
        ))
      ) : (
        <Paragraph>No responses</Paragraph>
      )}

      <Subheading>Response Fields:</Subheading>
      {endpoint.ResponseFields && endpoint.ResponseFields.length > 0 ? (
        <Table>
          <thead>
            <TableRow>
              <TableHeader>Field</TableHeader>
              <TableHeader>Explanation</TableHeader>
            </TableRow>
          </thead>
          <tbody>
            {endpoint.ResponseFields.map((field) => (
              <TableRow key={field.id}>
                <TableCell>{formatFieldName(field.field)}</TableCell>
                <TableCell>{field.explanation}</TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      ) : (
        <Paragraph>No response fields available</Paragraph>
      )}
    </ContentWrapper>
  );
}

export default EndpointPage;
