import axios from './axios';

const getDocs = async () => {
  try {
    const response = await axios.get('/doc');

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const docsService = {
  getDocs,
};

export default docsService;
