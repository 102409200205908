import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

const HeaderContainer = styled.header`
  background-color: #1f2937;
  color: #ffffff;
  padding: 15px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);

  @media (max-width: 768px) {
    padding: 10px 20px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  height: 40px;
  margin-right: 25px;

  @media (max-width: 768px) {
    height: 30px;
  }
`;

const TitleText = styled.h1`
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const BetaLabel = styled.span`
  background-color: #f59e0b;
  color: #ffffff;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 2px 8px;
  border-radius: 12px;
  margin-left: 15px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 0.7rem;
    padding: 1px 6px;
  }
`;

const BurgerIcon = styled.div`
  display: none;
  width: 25px;
  height: 2px;
  background-color: #fff;
  position: relative;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transition: transform 0.3s ease;
  }

  &::before {
    transform: translateY(-8px);
  }

  &::after {
    transform: translateY(8px);
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

function Header({ toggleSidebar }) {
  const location = useLocation();
  const isBeta = location.pathname.includes('/beta');

  return (
    <HeaderContainer>
      <LogoContainer>
        <LogoImage src='/qualiphy.png' alt='Logo' />
        <TitleText>
          API Docs
          {isBeta && <BetaLabel>Beta</BetaLabel>}
        </TitleText>
      </LogoContainer>
      <BurgerIcon onClick={toggleSidebar} />
    </HeaderContainer>
  );
}

export default Header;
