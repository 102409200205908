import React from 'react';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy, FaDownload } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import { apiHost } from '../constants';

const IntroWrapper = styled.div`
  overflow-y: auto;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    margin-bottom: 25px;
  }
`;

const Heading = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  color: #111827;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const BetaLabel = styled.span`
  background-color: #f59e0b;
  color: #ffffff;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 2px 8px;
  border-radius: 12px;
  margin-left: 15px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 0.7rem;
    padding: 1px 6px;
  }
`;

const Paragraph = styled.p`
  font-size: 1.125rem;
  margin-bottom: 20px;
  color: #4b5563;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 15px;
  }
`;

const BaseUrlSection = styled.div`
  margin-top: 50px;
  padding: 20px;
  background-color: #f4f7fc;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
`;

const BaseUrl = styled.div`
  font-size: 1rem;
  color: #1f2937;
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  code {
    background-color: #e2e8f0;
    padding: 4px 8px;
    border-radius: 4px;
    margin-right: 10px;
  }
`;

const CopyIcon = styled(FaCopy)`
  color: #4b5563;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #111827;
  }
`;

const PostmanButton = styled.a`
  display: inline-flex;
  align-items: center;
  margin-top: 50px;
  padding: 12px 20px;
  background-color: #ff6c37;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 6px;
  text-decoration: none;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e65b30;
  }

  svg {
    margin-right: 8px;
  }
`;

const IntroPageBeta = () => {
  const handleCopy = (text) => {
    toast.success('Copied to clipboard!');
  };

  return (
    <IntroWrapper>
      <HeadingContainer>
        <Heading>Welcome to the Qualiphy API Documentation</Heading>
        <BetaLabel>Beta</BetaLabel>
      </HeadingContainer>
      <Paragraph>
        Qualiphy is a comprehensive platform designed to enhance healthcare
        providers' ability to manage their practices effectively. This API
        documentation provides detailed information on how to integrate with the
        Qualiphy system, offering endpoints for managing practice exams, patient
        invites, attachments, and more.
      </Paragraph>
      <Paragraph>
        Whether you’re looking to automate your workflows, ensure compliance
        with industry regulations, or improve patient engagement, the Qualiphy
        API offers the tools and endpoints necessary to achieve these goals.
        Explore the endpoints listed in the sidebar to see how you can leverage
        the power of Qualiphy in your healthcare operations.
      </Paragraph>
      <Paragraph>
        Please refer to the individual endpoint documentation for specific
        details on parameters, responses, and example requests.
      </Paragraph>

      <BaseUrlSection>
        <Heading>Base URLs</Heading>
        <BaseUrl>
          <span>
            <strong>Sandbox:</strong>{' '}
            <code>https://staging-api.qualiphy.me/</code>
          </span>
          <CopyToClipboard
            text='https://staging-api.qualiphy.me/'
            onCopy={handleCopy}
          >
            <CopyIcon title='Copy URL' />
          </CopyToClipboard>
        </BaseUrl>
        <BaseUrl>
          <span>
            <strong>Production:</strong> <code>https://api.qualiphy.me/</code>
          </span>
          <CopyToClipboard text='https://api.qualiphy.me/' onCopy={handleCopy}>
            <CopyIcon title='Copy URL' />
          </CopyToClipboard>
        </BaseUrl>
      </BaseUrlSection>

      <PostmanButton
        href={`${apiHost}/betaPostmanCollection`}
        download='Qualiphy_Beta_API_Collection.json'
      >
        <FaDownload />
        Download Postman Collection
      </PostmanButton>
    </IntroWrapper>
  );
};

export default IntroPageBeta;
