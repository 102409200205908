import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import { Toaster, toast } from 'react-hot-toast';
import { ClipLoader } from 'react-spinners';

import Header from './components/Header';
import Sidebar from './components/Sidebar';
import EndpointPage from './components/EndpointPage';
import docsService from './services/docs-service';
import IntroPage from './components/IntroPage';
import IntroPageBeta from './components/IntroPageBeta';

const Layout = styled.div`
  display: flex;
  height: 100vh;
  background-color: #f4f7fc;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Main = styled.main`
  flex: 1;
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin: 20px;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 20px;
    margin: 10px;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [endpoints, setEndpoints] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDocs = async () => {
      try {
        const response = await docsService.getDocs();
        if (response?.error) {
          toast.error('Something went wrong. Please try again!');
        } else {
          setEndpoints(response);
        }
      } catch (error) {
        toast.error('Something went wrong. Please try again!');
      } finally {
        setLoading(false);
      }
    };

    fetchDocs();
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Router>
      <Header toggleSidebar={toggleSidebar} />
      <Layout>
        <Sidebar
          isOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
          endpoints={endpoints}
        />
        <Main>
          {loading ? (
            <LoaderContainer>
              <ClipLoader size={50} color={'#123abc'} loading={loading} />
            </LoaderContainer>
          ) : (
            <Routes>
              <Route path='/docs/api/intro' element={<IntroPage />} />
              {endpoints
                .filter((endpoint) => endpoint.isBeta === false)
                .map((endpoint) => (
                  <Route
                    key={endpoint.id}
                    path={`/docs/api/${endpoint.title
                      .toLowerCase()
                      .replace(/ /g, '-')}`}
                    element={<EndpointPage endpoint={endpoint} />}
                  />
                ))}
              <Route path='/beta/api/intro' element={<IntroPageBeta />} />
              {endpoints
                .filter((endpoint) => endpoint.isBeta === true)
                .map((endpoint) => (
                  <Route
                    key={endpoint.id}
                    path={`/beta/api/${endpoint.title
                      .toLowerCase()
                      .replace(/ /g, '-')}`}
                    element={<EndpointPage endpoint={endpoint} />}
                  />
                ))}
            </Routes>
          )}
        </Main>
      </Layout>
      <Toaster
        toastOptions={{
          success: {
            style: {
              background: 'green',
              color: 'white',
            },
          },
          error: {
            style: {
              background: 'red',
              color: 'white',
            },
          },
        }}
        position='top-right'
        reverseOrder={false}
      />
    </Router>
  );
}

export default App;
