import React from 'react';
import styled from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';

const SidebarContainer = styled.nav`
  width: 250px;
  background-color: #111827;
  padding: 30px 20px;
  color: #e5e7eb;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  transform: ${({ isOpen }) =>
    isOpen ? 'translateX(0)' : 'translateX(-100%)'};
  transition: transform 0.3s ease;
  z-index: 1000;

  @media (min-width: 769px) {
    position: static;
    transform: translateX(0);
  }
`;

const SectionHeading = styled.h3`
  font-size: 1.1rem;
  color: #9ca3af;
  margin-bottom: 20px;
  margin-top: 40px;
  padding-left: 10px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`;

const NavItem = styled(NavLink)`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  color: #e5e7eb;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  padding: 10px;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #374151;
    color: #ffffff;
  }

  &.active {
    background-color: #2563eb;
    color: #ffffff;
  }

  @media (max-width: 768px) {
    font-size: 0.95rem;
    margin-bottom: 10px;
  }
`;

function Sidebar({ isOpen, toggleSidebar, endpoints }) {
  const location = useLocation();
  const isBeta = location.pathname.includes('/beta');

  const filteredEndpoints = endpoints.filter(
    (endpoint) => endpoint.isBeta === (isBeta ? true : false)
  );

  const getSectionName = (method) => {
    switch (method) {
      case 'GET':
        return 'GET Requests';
      case 'POST':
        return 'POST Requests';
      case 'PUT':
        return 'PUT Requests';
      case 'DELETE':
        return 'DELETE Requests';
      default:
        return 'Other Requests';
    }
  };

  const groupedEndpoints = filteredEndpoints.reduce((acc, endpoint) => {
    const sectionName = getSectionName(endpoint.method);
    if (!acc[sectionName]) acc[sectionName] = [];
    acc[sectionName].push(endpoint);
    return acc;
  }, {});

  return (
    <SidebarContainer isOpen={isOpen}>
      <NavItem
        to={isBeta ? '/beta/api/intro' : '/docs/api/intro'}
        onClick={toggleSidebar}
        activeClassName='active'
      >
        Introduction
      </NavItem>
      {Object.keys(groupedEndpoints).map((sectionName) => (
        <div key={sectionName}>
          <SectionHeading>{sectionName}</SectionHeading>
          {groupedEndpoints[sectionName].map((endpoint) => (
            <NavItem
              key={endpoint.id}
              to={`${isBeta ? '/beta' : '/docs'}/api/${endpoint.title
                .toLowerCase()
                .replace(/ /g, '-')}`}
              onClick={toggleSidebar}
              activeClassName='active'
            >
              {endpoint.title}
            </NavItem>
          ))}
        </div>
      ))}
    </SidebarContainer>
  );
}

export default Sidebar;
