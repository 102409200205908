import axios from 'axios';

import { apiHost } from '../constants';

axios.defaults.baseURL = apiHost;

const axiosInstance = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=UTF-8',
  },
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response) {
      console.error('Network error:', error);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
